import React from 'react'

const Courses = () => {
  return (
    <div>
        <h2>This is Courses page</h2>
    </div>
  )
}

export default Courses