import logo from './logo.svg';
import About_us from './pages/About_us';
import Contact_us from './pages/Contact_us';
import Courses from './pages/Courses';
import Gallery from './pages/Gallery';
import Ourservices from './pages/Ourservices';
import Addenquiry from './pages/Addenquiry';

import Home from "./pages/Home"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import OurServices from './pages/Ourservices';

function App() {
  return (
    <>
   <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}>
          
        </Route>
        <Route path="about-us" element={<About_us/>}></Route>
        <Route path="courses" element={<Courses/>}></Route>
        <Route path="contact-us" element={<Contact_us/>}></Route>
        <Route path="gallery" element={<Gallery/>}></Route>
        <Route path="our-services" element={<Ourservices/>}></Route>
        <Route path="add-enquiry" element={<Addenquiry/>}></Route>
       
      </Routes>
    </BrowserRouter>

    </>
  );
}

export default App;
