import React from "react";

const Footer = () => {
  return (
    <footer class="footer ">
      <div class="container">
        <div class="row">
          <div class="col-md-6 copy-right">
            <p> &copy; 2024 All Rights Reserved. Webseller </p>
          </div>
          {/* <div class="col-md-6 copy-right ">
					<div class="footer_title row ">
						<div class='row'><p>Powered by :</p>
						<h5 >webseller</h5>
						</div>
						<a href="https://webseller.in" target="_blank"><div data-toggle="tooltip" title="" data-original-title="ABC Designs"> </div> </a>
					</div>
				</div>					 */}
          <div class='col-md-6'>
            <ul type="none" class="d-flex justify-content-end list-unstyled ">
			<li class=' mx-2'>
                <a
                  href="https://wa.me/yourphonenumber"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/img/instagram.png"
                    alt="WhatsApp"
                    style={{ width: "40px", height: "40px" }}
                  />
                </a>
              </li>
			  <li class=' mx-2'>
                <a
                  href="https://wa.me/yourphonenumber"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/img/facebook.png"
                    alt="WhatsApp"
                    style={{ width: "40px", height: "40px" }}
                  />
                </a>
              </li>
			  <li class=' mx-2'>
                <a
                  href="https://wa.me/yourphonenumber"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/img/twitter.png"
                    alt="WhatsApp"
                    style={{ width: "40px", height: "40px" }}
                  />
                </a>
              </li>
              <li class='mx-2'>
                <a
                  href="https://wa.me/yourphonenumber"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/img/whatsapp.png"
                    alt="WhatsApp"
                    style={{ width: "40px", height: "40px" }}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
