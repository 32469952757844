import React from 'react'

const Gallery = () => {
  return (
    <div>
            <h2> This is Gallery page</h2>

    </div>
  )
}

export default Gallery