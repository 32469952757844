import React, { useState } from 'react';
import Topnavigation from './Topnavigation';
import Footer from './Footer'


const Contact_us = () => {

  const [formData, setFormData] = useState({
    name: '',
    lastName: '',
    email: '',
    contactNumber: '',
    address: '',
    remarks: '',
    medium: '',
    course: '',
  });
  const [errors, setErrors] = useState({});


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    if (!formData.name) {
      errors.name = 'Name is required';
    }
    if (!formData.lastName) {
      errors.lastName = "Last Name is required";
    }
    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
    }
    if (!formData.contactNumber) {
      errors.contactNumber = 'Mobile number is required';
    } else if (!/^\d{10}$/.test(formData.contactNumber)) {
      errors.contactNumber = 'Mobile number is invalid';
    }
    if (!formData.address) {
      errors.address = 'Address is required';
    }
    if (!formData.remarks) {
      errors.remarks = 'Remarks is required';
    }
    if (!formData.medium) {
      errors.medium = 'Medium select an option';
    }
    if (!formData.course) {
      errors.course = 'Course select an option';
    }

    if (Object.keys(errors).length === 0) {
      // Form is valid, you can submit it
      console.log('Form submitted:', formData);
    } else {
      // Form is invalid, set the errors state
      setErrors(errors);
    }

  };
  return (
    <>

     <Topnavigation></Topnavigation>
        <div>
        <img src="./assets/img/bag01.jpg" style={{width:"100%",height:400}} />

        </div>
        <div>

          <h2 style={{textAlign:'center', marginTop:50}}>Contact Us Inquiry Form</h2>
        </div>
        <div class='container'> 

        {/* <div class='row' style={{marginTop:75,marginBottom:75}}>
        <div class="col-12 col-lg-3 col-xl-8 row">
        <img src="./assets/img/address.png" style={{width:"15",height:60}} />
        <div style={{marginLeft:40}}>
        <div ><h5>Address</h5> </div>
        <div><p>Sangam Nagar Indore</p></div>
        </div>
          </div>

          <div class="col-12 col-lg-3 col-xl-4 row">
        <img src="./assets/img/phonecolor.png" style={{width:"15",height:60}} />
        <div style={{marginLeft:40}}>
        <div ><h5>Contact</h5> </div>
        <div><p>7999061255</p></div>
        </div>
          </div>
        </div> */}

      
        <form style={{marginTop:40,}} onSubmit={handleSubmit}>
            <div class="row">
           
                <div class="col-8 row">
                   
                    <div class="col-6">
                    <input class="form-control"  type="text"
                                                  name="name"
                                                  placeholder='Name'
                                                  value={formData.name}
                                                  onChange={handleInputChange}
                                                  />
                                 {errors.name && <span style={{color:'red'}}>{errors.name}</span>}                
                    </div>

                    <div class="col-6">
                    <input class="form-control" type="text" placeholder="Last Name" name="lastName" onChange={handleInputChange} value={formData.lastName}/>
                    {errors.lastName && <span style={{color:'red'}}>{errors.lastName}</span>}
                    </div>

                    <div class="col-6">
                    <input class="form-control" type="number" placeholder="Contact No." name="contactNumber" onChange={handleInputChange} value={formData.contactNumber}/>
                    {errors.contactNumber && <span style={{color:'red'}}>{errors.contactNumber}</span>}
                    </div>

                    <div class="col-6">
                    <input class="form-control" type="email" name="email" value={formData.email} onChange={handleInputChange} placeholder="Email Id" />
                    {errors.email && <span style={{color:'red'}}>{errors.email}</span>}
                    </div>

                    <div class="col-12">
                    <textarea class="form-control"  placeholder='Place of Residence' type="text" name="address" value={formData.address} onChange={handleInputChange} rows="3"></textarea>
                    {errors.address && <span style={{color:'red'}}>{errors.address}</span>}
                    </div>

                    <div class="col-6">
                   
              <div class="mb-3">
                
                <select class="form-control"  name="medium"
                      value={formData.medium}
                      onChange={handleInputChange}>
                  <option selected>Select Medium</option>
                  <option value="1">Hindi Medium</option>
                  <option value="2">English Medium</option>
                  <option value="3">CBSE</option>
                 
                </select>
                {errors.medium && <span style={{color:'red'}}>{errors.medium}</span>}
              </div>
                    </div>

                    <div class="col-6">
                   
                   <div class="mb-3">
                     
                     <select class="form-control" name="course"
                      value={formData.course}
                      onChange={handleInputChange}>
                       <option selected>Select Course</option>
                       <option value="1">9th</option>
                       <option value="2">10th</option>
                       <option value="3">11th</option>
                       <option value="4">12th</option>
                       <option value="5">PCM</option>
                       <option value="5">BIO</option>
                       <option value="5">COMMERCE</option>
                     </select>
                     {errors.course && <span style={{color:'red'}}>{errors.course}</span>}
                   </div>
                         </div>

                         <div class="col-12">
                    <textarea class="form-control"  onChange={handleInputChange}  placeholder='Remarks' type="text" name='remarks' value={formData.remarks} rows="3"></textarea>
                    {errors.remarks && <span style={{color:'red'}}>{errors.remarks}</span>}
                    </div>
                    <div style={{margin:10,marginLeft:15}}>
                    <button type="submit" class="btn btn-outline-success">Submit</button>  
                    </div>
                    
                </div>
               
                
                <div class="col-4">
                <img src="./assets/img/classcon00.png" style={{width:"100%",height:500,borderRadius:4}} />
                </div>
            </div>
            </form>
          
              

        </div>
        <Footer></Footer>
    </>
  )
}

export default Contact_us