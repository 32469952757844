import axios from "axios";
const API_BASE_URL = 'https://api.studylinkclassesedu.in/adminapi/';

export const Add_Enquiry_API = async (payload) => {

  const headers = {
    'Content-Type': 'application/json',
    'Sessionkey': "efa7f43cdcaf991d990d076b073c89fd"
    } 

  try {
    const { data } = await axios.post(
      `${API_BASE_URL}enquiry/add_enquiry`,
      
      payload, {headers:headers});
    return data;
  } catch (err) {
    return err.response.data    
  }
};


// export const PostAPI = async (url,payload) => {

//   const headers = {
//   'Content-Type': 'application/json',
//   'Sessionkey': localStorage.getItem("token")
//   } 
//   try {
//     const { data } = await axios.post(
//       `${API_BASE_URL}${url}`,
//       payload,{headers:headers});
//     return data;
//   } catch (err) {
//     debugger;
//     if(err && typeof err.response=='undefined'){
//       var resObj = { "response_code": 500, "data": {}, "message": "Something went wrong, please contact admin." }
//       var testData = resObj;
//       return testData;
//     }else{

//       if(err.response.data.response_code=='401'){
//         localStorage.clear();
//         window.location.href = "/login";
//       }
     

//     }
//     return err.response.data    
//   }

  
// };


