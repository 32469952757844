
import React, { useEffect } from 'react';
import $ from 'jquery'; // Import jQuery
import Slider from './Slider';
import Footer from './Footer';
import { Link } from 'react-router-dom';

function Topnavigation() {


	useEffect(() => {
		// Define the collapseNavbar function
		function collapseNavbar() {
		  if ($(".navbar").offset().top > 50) {
			$(".navbar-fixed-top").addClass("top-nav-collapse");
		  } else {
			$(".navbar-fixed-top").removeClass("top-nav-collapse");
		  }
		}
	
		// Call collapseNavbar on scroll
		$(window).scroll(collapseNavbar);
		// Call collapseNavbar on document ready
		$(document).ready(collapseNavbar);
	
		// Cleanup function
		return () => {
		  // Remove the scroll event listener when the component unmounts
		  $(window).off('scroll', collapseNavbar);
		};
	  }, []);
	
	


  return (
    <>


	<div class="head_patch">
			<a href="#" id="click-a"><i class="fa fa-bars"></i> MENU</a>
			<p class="text-right"> Call Us : <a href="tel:7999061255"> 7999061255 </a>  </p>
	</div>
	<nav class="navbar navbar-expand-lg navbar-fixed-top">
		<a href="#" id="click-a" class="close_menu"><i class="fa fa-times" aria-hidden="true"></i> </a>
		<div class="container">
			<a class="navbar-brand logo" href="/">
				<img src="assets/img/logo_1.png" class="img-fluid mx-auto d-block" width="70" alt=""/>			
		    </a>
			<div class="navbar-collapse" id="navbarResponsive">
				<div class="top_menu">
					<ul>
						<li><a href="/"> Home </a></li>
						
					</ul>
					<ul class="call_us">
						<li> Call Us : <a href="tel:7999061255"> 7999061255 </a> </li>
					</ul>
				</div>
				<ul class="navbar-nav ml-auto">
					
					<li class="res_men"><a href="#"> Home </a></li>
					<li> <Link to="/about-us">About Us</Link></li>
					<li><Link to="/our-services">Our Services</Link></li>
					
					<li class="dropdown">
											<a href="courses" class="dropdown-toggle " id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Courses</a>
						<div class="dropdown-menu" aria-labelledby="navbarDropdown">
							<a href="#" class="">S.S.C.</a><a href="#" class="">Commerce</a><a href="#" class="">Science</a>						</div>
					</li>
										
					{/* <li> <Link to="/add-enquiry">Add Enquiry</Link></li> */}
					
					<li class="dropdown">
						<a href="courses" class="dropdown-toggle " id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Gallery</a>
						<div class="dropdown-menu" aria-labelledby="navbarDropdown">
							<a href="#" class="">Photos</a><a href="#" class="">Videos</a></div>
					</li>
					 
					
					
					
					<li><Link to="/contact-us">Contact us</Link></li>

					{/* <li><a href="#" class="online_add"> Online Admission </a></li> */}
									
					<li><Link to="https://app.studylinkclassesedu.in/" class="online_add"> Login</Link> </li>
				</ul>
			</div>
		</div>
	</nav>
	
    </>
  );
}

export default Topnavigation;


