
import React, { useEffect } from 'react';
import $ from 'jquery'; // Import jQuery
import Slider from './Slider';
import Footer from './Footer';
import Topnavigation from './Topnavigation';
import Login from './login';

function Home() {


	useEffect(() => {
		// Define the collapseNavbar function
		function collapseNavbar() {
		  if ($(".navbar").offset().top > 50) {
			$(".navbar-fixed-top").addClass("top-nav-collapse");
		  } else {
			$(".navbar-fixed-top").removeClass("top-nav-collapse");
		  }
		}
	
		// Call collapseNavbar on scroll
		$(window).scroll(collapseNavbar);
		// Call collapseNavbar on document ready
		$(document).ready(collapseNavbar);
	
		// Cleanup function
		return () => {
		  // Remove the scroll event listener when the component unmounts
		  $(window).off('scroll', collapseNavbar);
		};
	  }, []);
	
	


  return (
    <>


	<Topnavigation></Topnavigation>
	
	
	<Slider></Slider>	

	<section class="home">
		<div class="container">
			
			<div class="row tag">
				<div class="col-lg-3 col-md-3">
					<h4> Study-link Coaching Classes is now <span>12 years</span> complete, having 1 branche in the sangam nagar of Indore. </h4>
				</div>
				<div class="col-lg-9 col-md-9">
					<p> Bharat Ghate, the founder, principal of the classes aware of the humble beginning of <strong>Study-link Coaching Classes in the year 2012 with just 12 students,</strong> is glad to have been Able to expand the classes, to teach hundress of students today, with more than 12 staff assisting him. <br />
						The bright performances of Study-link Coaching Classes’ students year after year have been the driving force behind its success. The commitment of the teachers to draw the best out of   weak students has also been an important factor contributing to the trust the classes enjoys among the parents. </p>
				</div>
			</div>
			<br />
			<div class="row text-center">
				<div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<img style={{height:100 , width:100}} src="assets/img/books.png" class="img-fluid mx-auto d-block" alt=""/>					<h3 class="text-uppercase"> Well-Curated Study Material </h3>
					<p> Our experienced faculty strive hard for extensive coverage of all courses in the form of lectures, study material and tests to ensure that students cover everything. </p>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<img style={{height:100 , width:100}} src="assets/img/student.png" class="img-fluid mx-auto d-block" alt=""/>					<h3 class="text-uppercase"> Experienced Faculty </h3>
					<p> Our faculty add experience and value to student’s learning constantly with advanced teaching techniques and quick doubt clarification. </p>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<img style={{height:100 , width:100}} src="assets/img/book.png" class="img-fluid mx-auto d-block" alt=""/>					<h3 class="text-uppercase"> Tests &amp; Assessment </h3>
					<p> Our topic-wise tests, major-tests and rigourous feedback mechanism help the students to know their level of understanding</p>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<img style={{height:100 , width:100}} src="assets/img/network.png" class="img-fluid mx-auto d-block" alt=""/>					<h3 class="text-uppercase"> Network of Success </h3>
					<p> Strongly networked community of successful students; Ex-students help our students build connections and offer career guidance. </p>
				</div>
			</div>
			<br /> <br /> <br />  <br /> 
		</div>
	</section>	
	
	<section class="features-section" style={{backgroundImage:`url(assets/img/courses_offered.jpg)`}} data-stellar-background-ratio="0.5">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 text-center">
					<h2> Courses <span class=""> Offered </span> </h2>
				</div>
			</div>
			<br />
			<div class="row text-center">
				<div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
					<div class="item partner">
						<div class="img_box">
							<img src="assets/img/ssc.jpg" class="img-fluid mx-auto d-block" alt=""/>						</div>
						<h6>SSC</h6>
						<p> <strong> S. P. (Study-link Pattern) Batches - All Subjects:</strong>  <br /> <strong> Commencing from 15th November:</strong> Sunday to Sunday and Holidays daily 3 to 4 hrs. </p>
						<div class="link-btn">
							<a href="#">read more</a>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
					<div class="item partner">
						<div class="img_box">
							<img src="assets/img/ssc_1.jpg" class="img-fluid mx-auto d-block" alt=""/>						</div>
						<h6> Commerce </h6>
						<p>  <strong> S.L.B. (Serious Learner's Batches):</strong>  <br />   <strong> Commencing from 1st week of December:</strong> Sunday to Sunday and Holidays daily 3 to 4 hrs</p>
						<div class="link-btn">
							<a href="#">read more</a>
						</div>
						
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
					<div class="item partner">
						<div class="img_box">
							<img src="assets/img/ssc_2.jpg" class="img-fluid mx-auto d-block" alt=""/>						</div>
						<h6> Science </h6>
						<p> <strong> Commencing from 1st week of December</strong>: Sunday to Sunday and Holidays daily 3 to 4 hrs. <strong> From 15th April to 31st May:</strong> Daily 6 Hours in the Morning or Evening depending upon batches.</p>
						<div class="link-btn">
							<a href="#">read more</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	
	
	<section class="why_choose">
		<div class="container">
			<div class="row">
				<div class="col-md-12 text-center">
				
					<h4 class="text-uppercase purple"> Highlights </h4>
					<h3 class="text-uppercase"> We have unique facilities for our student </h3>
				</div>				
			</div>
			<br />
			<div class="row text-center">
				<div class="col-lg-8">
					<div class="thumb">
						<header>
							<div id="carouselExample" class="carousel slide" data-ride="carousel">
								<ol class="carousel-indicators">
									<li data-target="#carouselExample" data-slide-to="0" class="active"></li>
									<li data-target="#carouselExample" data-slide-to="1"></li>
									<li data-target="#carouselExample" data-slide-to="2"></li>
									<li data-target="#carouselExample" data-slide-to="3"></li>
								</ol>
								<div class="carousel-inner" role="listbox">
																		<div class="carousel-item active">
										<a href="/events"> <img src="assets/img/upload/original_1.jpg" class="img-fluid mx-auto d-block" alt=""/> </a>
									</div>
																		<div class="carousel-item">
										<a href="/events"> <img src="assets/img/upload/original_1.jpg" class="img-fluid mx-auto d-block" alt=""/> </a>
									</div>
																		<div class="carousel-item">
										<a href="/events"> <img src="assets/img/upload/original_2.jpg" class="img-fluid mx-auto d-block" alt=""/> </a>
									</div>
																		<div class="carousel-item">
										<a href="/events"> <img src="assets/img/upload/original_3.jpg" class="img-fluid mx-auto d-block" alt=""/> </a>
									</div>
																		<div class="carousel-item">
										<a href="/events"> <img src="assets/img/upload/original_3.jpg" class="img-fluid mx-auto d-block" alt=""/> </a>
									</div>
																		<div class="carousel-item">
										<a href="/events"> <img src="assets/img/upload/original_4.jpg" class="img-fluid mx-auto d-block" alt=""/> </a>
									</div>
																		<div class="carousel-item">
										<a href="/events"> <img src="assets/img/upload/original_1.jpg" class="img-fluid mx-auto d-block" alt=""/> </a>
									</div>
																		<div class="carousel-item">
										<a href="/events"> <img src="assets/img/upload/original_2.jpg" class="img-fluid mx-auto d-block" alt=""/> </a>
									</div>
																		<div class="carousel-item">
										<a href="/events"> <img src="assets/img/upload/original_3.jpg" class="img-fluid mx-auto d-block" alt=""/> </a>
									</div>
								</div>
							</div>
						</header>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="lg_facebook">					
						<div class="fb-page" data-href="https://www.facebook.com/saraswaticlassesmumbai/" data-tabs="timeline" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true" data-height="600px">
						<blockquote cite="https://www.facebook.com/saraswaticlassesmumbai/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/saraswaticlassesmumbai/">Study-link Coaching Classes</a></blockquote></div>
					</div>
					<div class="sm_facebook">	
						<div class="fb-page" data-href="https://www.facebook.com/saraswaticlassesmumbai/" data-width="380"  data-hide-cover="false" data-show-facepile="true"></div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="video_sec">
		<div class="container">
			<div class="row">
				<div class="col-md-12 text-center">
					<h2 class="purple mb-20"> OUR PHOTOS </h2>
				</div>
			</div>
			<div class="row justify-content-md-center ">
				
				<div class="col-lg-4 col-md-6 col-sm-6">
					<div class="youtube-link card" youtubeid="RjZDGqewhnA">
						<div class="carousel-item active">
							<a href=""> <img style={{height:175, width:350}} src="assets/img/pens01.jpg" class="img-fluid mx-auto d-block" alt=""/> </a>
						</div>
						<p></p>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 col-sm-6">
					<div class="youtube-link card" youtubeid="5Cr2ngEvIPQ">
						<div class="carousel-item active">
							<a href=""> <img style={{height:175, width:350}} src="assets/img/class00.jpg" class="img-fluid mx-auto d-block" alt=""/> </a>
						</div>
						
						<p></p>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 col-sm-6">
					<div class="youtube-link card" youtubeid="tDcJS8W4V04">
						<div class="carousel-item active">
							<a href=""> <img style={{height:175, width:350}} src="assets/img/slideg05.jpg" class="img-fluid mx-auto d-block" alt=""/> </a>
						</div>
						<p>  </p>
					</div>
				</div>
								<div class="col-lg-4 col-md-6 col-sm-6">
					<div class="youtube-link card" youtubeid="ms7T93oPtoo">
						<div class="carousel-item active">
							<a href=""> <img style={{height:175, width:350}} src="assets/img/class03.jpg" class="img-fluid mx-auto d-block" alt=""/> </a>
						</div>
						<p>  </p>
					</div>
				</div>
								<div class="col-lg-4 col-md-6 col-sm-6">
					<div class="youtube-link card" youtubeid="boT5k_ii3hw">
						<div class="carousel-item active">
							<a href=""> <img style={{height:175, width:350}} src="assets/img/bg_2.jpg" class="img-fluid mx-auto d-block" alt=""/> </a>
						</div>
						<p>  </p>
					</div>
				</div>
								<div class="col-lg-4 col-md-6 col-sm-6">
					<div class="youtube-link card" youtubeid="wGYqF1d_nZM">
						<div class="carousel-item active">
							<a href=""> <img style={{height:175, width:350}} src="assets/img/class02.jpg" class="img-fluid mx-auto d-block" alt=""/> </a>
						</div>
						<p>  </p>
					</div>
				</div>
								{/* <div class="col-lg-4 col-md-6 col-sm-6">
					<div class="youtube-link card" youtubeid="6mwR_IY6RNM">
						<div class="carousel-item active">
							<a href=""> <img style={{height:175, width:350}} src="assets/img/classcon.jpg" class="img-fluid mx-auto d-block" alt=""/> </a>
						</div>
						<p> Study-link Coaching Classes  </p>
					</div>
				</div> */}
							</div>
		</div>
	</section>
	
	<section class="testimonial" style={{ backgroundImage: 'url(/assets/img/bg_2.jpg)', backgroundPosition: '50% 0' }} data-stellar-background-ratio="0.5">
		<div class="container">
			<div class="row">
				<div class="col-md-5">
					<div class="card text-center addmissions_open">
						<h1> Admission </h1>
						<h1> OPEN  </h1>
						<h1> 2023 - 24  </h1>
						<a href="https://app.studylinkclassesedu.in/" class="btn"> Admission Now </a>
					</div>
					
				</div>
				<div class="col-md-7 text-center">
					<header>						
						<h2 class="line-bottom-center text-center"> Our Happy Students say </h2>
						<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
							<ol class="carousel-indicators">
								<li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
								<li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
								<li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
							</ol>
							<div class="carousel-inner" role="listbox">
								<div class="carousel-item active">								
									<p class="font-15"> <em> Upto now my experience with Study-link Classes was superb. The lectures were detailed and interactive. These lectures have improved my thinking skill, ability and given me knowledge. All the concepts are taken one by one and are well explained. The multimedia lectures are very good and amazing. </em> </p>
									<h4 class="author text-theme-color-2 mb-0">  </h4>
								</div>
								<div class="carousel-item">
									<p class="font-15"> <em> It has been a very good experience for me and concepts are getting cleared.Personal attention is given. I enjoy coming here. All the teachers and sirs teach in such a way that we all understand it very clearly.  All are friendly and I love to converse with them. </em> </p>
									<h4 class="author text-theme-color-2 mb-0"> </h4>
								</div>
								<div class="carousel-item">
									<p class="font-15"> <em> What's in a name? Study-link Classes is a name still not very well known to the education world of Mumbai but with my little experience here, I can undoubtedly claim that it is a "small package bada dhamaka". No questions can be raised about the level of teaching and values here. </em> </p>
									<h4 class="author text-theme-color-2 mb-0">  </h4>
								</div>
							</div>
						</div>
					</header>
				</div>
			</div>
		</div>
	</section>
 	<Footer></Footer>
    </>
  );
}

export default Home;


