import React from 'react'
import Slider from './Slider'
import Topnavigation from './Topnavigation'
import Footer from './Footer'
import './style.css';

const About_us = () => {
  return (
    <>	
		<Topnavigation></Topnavigation>
        <Slider></Slider>

		<section class="py-3 py-md-5 py-xl-8">
		<div class="container">
			<div class="row gy-3 gy-md-4 gy-lg-0 align-items-lg-center">
			<div class="col-12 col-lg-6 col-xl-5">
				<img style={{height:400}} class="img-fluid rounded" loading="lazy" src="./assets/img/pens.jpg" alt=""/>
			</div>
			<div class="col-12 col-lg-6 col-xl-7">
				<div class="row justify-content-xl-center">
				<div class="col-12 col-xl-11">
					<h5 class="h1 mb-3">Welcome to Study-Link's Coaching Center!</h5>
					<p class="lead fs-4 text-secondary mb-3">With a decade of dedicated service and expertise in education, we pride ourselves on providing exceptional coaching tailored to students aged 5 to 15. Whether it's mastering English, Hindi, or excelling in the CBSE curriculum, we are here to guide and support your child's educational journey.</p>
					<p class="mb-5">Personalized Learning: We understand that each student is unique, with their own strengths and areas for growth. That's why we tailor our teaching methods to cater to individual learning styles, ensuring that every student receives the attention they need to succeed.</p>
					<div class="row gy-4 gy-md-0 gx-xxl-5X">
					<div class="col-12 col-md-6">
						<div class="d-flex">
						<div class="me-4 text-primary">
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16">
							<path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
							</svg>
						</div>
						<div>
							<h4 class="mb-3">Experienced Faculty:</h4>
							<p class="text-secondary mb-0">Our team of experienced educators brings a wealth of knowledge and passion to the classroom. With years of teaching experience under their belts, they are dedicated to nurturing students' intellectual curiosity and fostering a love for learning.</p>
						</div>
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="d-flex">
						<div class="me-4 text-primary">
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-fire" viewBox="0 0 16 16">
							<path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16Zm0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15Z" />
							</svg>
						</div>
						<div>
							<h4 class="mb-3">Comprehensive Curriculum:</h4>
							<p class="text-secondary mb-0">Whether your child needs help with foundational concepts or wants to dive deeper into advanced topics, our comprehensive curriculum has got you covered. </p>
						</div>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
			</div>
		</div>
		</section>

		{/* ------------------------------------------------------------------------------------------------------------- */}

		<section class="py-3  py-xl-8">
		<div class="container">
			<div>
				<h5>Interactive Learning:</h5>
				<p>Learning should be engaging and fun! That's why we incorporate interactive activities, multimedia resources, and real-world examples into our lessons to keep students actively engaged and motivated to learn.</p>
			</div>
			<div>
				<h5>Holistic Development:</h5>
				<p>Education is about more than just academics. We believe in nurturing the holistic development of our students, helping them build essential life skills such as critical thinking, communication, and problem-solving abilities that will serve them well beyond the classroom.</p>
			</div>
		</div>
		</section>

		{/* This is flip box code*/}
		
		{/* <div class='container'>
			<div class='col-sm-12'>
		<div class="flip-box row ">

				<div class="flip-box-inner col-lg-3 col-md-3 col-sm-3 col-xs-6">
					
					<div style={{padding:10}} class="flip-box-front">
					<div style={{textAlign:'center'}}><h6>This is content</h6></div>
					<div style={{color:'white' ,text:'bold'}} >Education-focused content is content that is created by educators to engage, inspire and inform their students. Gone are the days when students learned from only a textbook and a lecture.</div>
					</div>
					<div style={{padding:10}} class="flip-box-back">
					<div style={{textAlign:'center'}}><h6>This is content</h6></div>
					Educational content is something that can be creative and personalized to each learner’s needs.
					</div>
				</div>

				<div  class="flip-box-inner col-lg-3 col-md-3 col-sm-3 col-xs-6">
				<div style={{padding:10}} class="flip-box-front">
					<div style={{textAlign:'center'}}><h6>This is content</h6></div>
					<div style={{color:'white' ,text:'bold'}} >As a whole, educational content is versatile, accessible, informational, and should be created in a manner that helps you achieve your student’s learning goals.</div>
					</div>
					<div style={{padding:10}} class="flip-box-back">
					<div style={{textAlign:'center'}}><h6>This is content</h6></div>
					For example, educational content can be helpful for teaching students of all ages the same topic and story. 
					</div>
				</div>
				<div  class="flip-box-inner col-lg-3 col-md-3 col-sm-3 col-xs-6">
				<div style={{padding:10}} class="flip-box-front">
					<div style={{textAlign:'center'}}><h6>This is content</h6></div>
					<div style={{color:'white' ,text:'bold'}} >The type of content you choose to introduce while educating students is incredibly important; it can be the catalyst for growth in a student's journey.</div>
					</div>
					<div style={{padding:10}} class="flip-box-back">
					<div style={{textAlign:'center'}}><h6>This is content</h6></div>
					Content in education adds more context to lesson plans while showcasing various viewpoints and ideas.
					</div>
				</div>
				<div  class="flip-box-inner col-lg-3 col-md-3 col-sm-6 col-xs-6">
				<div style={{padding:10}} class="flip-box-front">
					<div style={{textAlign:'center'}}><h6>This is content</h6></div>
					<div style={{color:'white' ,text:'bold'}} >Only the best structured content and information will turn the novice learner into an expert, and the pressure relies on the teachers to put their best foot forward when it comes to developing the best lessons. </div>
					</div>
					<div style={{padding:10}} class="flip-box-back">
					<div style={{textAlign:'center'}}><h6>This is content</h6></div>
					There are many ways in which content provides meaningful learning experiences. Great content promotes passion and motivation throughout the learning process. 
					</div>
				</div>
	</div>
</div>
</div> */}

      
	<Footer></Footer>
    </>
  )
}

export default About_us