import React from 'react'
import Topnavigation from './Topnavigation';
import Footer from './Footer';

const OurServices = () => {
  return (
    
    <>
      <Topnavigation></Topnavigation>

       
         
           <div > 
           <img src="./assets/img/class01.jpg" style={{width:"100%",height:475}} />
           </div>
          
           <div class="container">  
        <div style={{marginTop:40}} class="row">
          <div class="col-12 col-lg-3 col-xl-4">
              <h4>Template Details:</h4>
              <div  style={{marginTop:20}}>
              <h5>Author: Bharat Ghate</h5>
              </div>

              <div  style={{marginTop:20}}>
              <h5>Released: may 2012</h5>
              </div>

              <div  style={{marginTop:20}}>
              <h5>Address: Sangam Nagar Indore</h5>
              </div>

              <div style={{marginTop:125,marginLeft:25}}>
              <button type="button" class="btn btn-info">Preview</button>

              </div>

              
          </div>

          <div class="col-12 col-lg-9 col-xl-8">
          <h4>Description:</h4>
          <div >
           
            <p>Courses and Curriculum: Education websites often offer a variety of courses catering to different subjects, levels, and learning objectives. These courses may range from academic subjects like math, science, history, and literature to specialized topics such as coding, business management, or language learning.</p>
            <p>Interactive Learning Materials: These websites typically provide interactive learning materials such as videos, presentations, quizzes, and simulations to engage learners and reinforce concepts. These materials may be created by educators or sourced from reputable educational institutions and organizations.</p>
            <p>Learning Management System (LMS): Many education websites include a learning management system that allows users to track their progress, access course materials, submit assignments, and communicate with instructors or peers. LMS features streamline the learning experience and provide a centralized platform for education-related activities.</p>
            
            </div>
          </div>
        </div>

        <div class="row row-cols-1 row-cols-md-2 g-4" style={{marginTop:50, marginBottom:50}}>
  <div class="col">
    <div class="card">
      <img style={{height:175}} src="./assets/img/class01.jpg" class="card-img-top" alt="..."/>
      <div class="card-body" style={{height:207}}>
        <h5 class="card-title"> Giving The Time</h5>
        <p class="card-text">Giving time to study is essential for academic success and personal growth. Setting aside dedicated time each day allows for focused learning.</p>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
      <img  style={{height:175}} src="./assets/img/class03.jpg" class="card-img-top" alt="..."/>
      <div class="card-body" style={{height:207}}>
        <h5 class="card-title">Computer</h5>
        <p class="card-text">Computers are the backbone of the digital age, revolutionizing how we work, communicate, and interact with the world.</p>
      </div>
    </div>
  </div>
  <div class="col" >
    <div class="card">
      <img style={{height:175}} src="./assets/img/class02.jpg" class="card-img-top" alt="..."/>
      <div class="card-body" style={{height:207}}>
        <h5 class="card-title">Study</h5>
        <p class="card-text">"Studying is the gateway to knowledge and growth. It's the process through which we expand our minds, challenge our assumptions, and discover new perspectives.</p>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card" >
      <img style={{height:175}} src="./assets/img/class00.jpg" class="card-img-top" alt="..."/>
      <div class="card-body" style={{height:207}}>
        <h5 class="card-title">Library</h5>
        <p class="card-text">Libraries are more than just repositories of books; they are vibrant hubs of knowledge, creativity, and community.</p>
      </div>
    </div>
  </div>
</div>


        </div>
       <Footer></Footer>
    </>
  )
}

export default OurServices