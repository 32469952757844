import React, { useState } from "react";
import Topnavigation from "./Topnavigation";
import Footer from "./Footer";
import { Add_Enquiry_API } from "../Services/Service";

const formDefaultValue = {
  name: "",
  email: "",
  contact_no: "",
  message: "",
};
const Addenquiry = () => {
  const [formData, setFormData] = useState(formDefaultValue);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = {};
    if (!formData.name) {
      errors.name = "Name is required";
    }

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }
    if (!formData.contact_no) {
      errors.contact_no = "Mobile number is required";
    } else if (!/^\d{10}$/.test(formData.contact_no)) {
      errors.contact_no = "Mobile number is invalid";
    }

    if (!formData.message) {
      errors.message = "Message is required";
    }

    // if (Object.keys(errors).length === 0) {
    //   // Form is valid, you can submit it
    //   console.log('Form submitted:', formData);
    // } else {
    //   // Form is invalid, set the errors state
    //   setErrors(errors);
    // }

    const res = await Add_Enquiry_API(formData);
    // debugger;
    if (res.response_code === 200) {
      console.log("MY_ADD_SUCCESSFULLY", res.message);
      alert("Successfully added enquiry");
      setFormData(formDefaultValue);

      // setLoader(false);
    } else if (res.response_code === 401) {
      alert("NO Add Data");
      console.log("MY_ADD_NOT", res.message);
      //   setLoader(false);
    }
  };
  return (
    <>
      <Topnavigation></Topnavigation>
      <div>
        <img
          src="./assets/img/slideg05.jpg"
          style={{ width: "100%", height: 425 }}
        />
      </div>
      <div>
        <h2 style={{ textAlign: "center", marginTop: 50 }}>Add Enquiry </h2>
      </div>
      <div class="container">
        <div class="row" style={{ marginTop: 75, marginBottom: 75 }}>
          <div class="col-12 col-lg-3 col-xl-8 row">
            <img
              src="./assets/img/address.png"
              style={{ width: "15", height: 60 }}
            />
            <div style={{ marginLeft: 40 }}>
              <div>
                <h5>Address</h5>{" "}
              </div>
              <div>
                <p>Sangam Nagar Indore</p>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-3 col-xl-4 row">
            <img
              src="./assets/img/phonecolor.png"
              style={{ width: "15", height: 60 }}
            />
            <div style={{ marginLeft: 40 }}>
              <div>
                <h5>Contact</h5>{" "}
              </div>
              <div>
                <p>7999061255</p>
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit} style={{ marginBottom: 20 }}>
          <div class="row">
            <div class="col-8 row">
              <div class="col-6">
                <input
                  class="form-control"
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
                {errors.name && (
                  <span style={{ color: "red" }}>{errors.name}</span>
                )}
              </div>

              <div class="col-6">
                <input
                  class="form-control"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Email Id"
                />
                {errors.email && (
                  <span style={{ color: "red" }}>{errors.email}</span>
                )}
              </div>

              <div class="col-6">
                <input
                  class="form-control"
                  type="number"
                  name="contact_no"
                  value={formData.contact_no}
                  onChange={handleInputChange}
                  placeholder="contact_no"
                />
                {errors.contact_no && (
                  <span style={{ color: "red" }}>{errors.contact_no}</span>
                )}
              </div>
{/* 
              <div class="col-6">
                <input
                  class="form-control"
                  type="number"
                  placeholder="Contact No."
                  name="contact_no"
                  onChange={handleInputChange}
                  value={formData.contact_no}
                />

                {errors.contact_no && (
                  <span style={{ color: "red" }}>{errors.contact_no}</span>
                )}
              </div> */}

              <div class="col-12">
                <textarea
                  class="form-control"
                  placeholder="Message"
                  type="text"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  rows="3"
                ></textarea>
                {errors.message && (
                  <span style={{ color: "red" }}>{errors.message}</span>
                )}
              </div>

              <div style={{ margin: 10, marginLeft: 15 }}>
                <button type="submit" class="btn btn-outline-success">
                  Submit
                </button>
              </div>
            </div>

            <div class="col-4">
              <img
                src="./assets/img/pens.jpg"
                style={{ width: "100%", height: 400, borderRadius: 4 }}
              />
            </div>
          </div>
        </form>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Addenquiry;
