
import React, { useEffect } from 'react';
import $ from 'jquery'; // Import jQuery

function Slider() {


	useEffect(() => {
		// Load Bootstrap JavaScript
		const bootstrapScript = document.createElement('script');
		bootstrapScript.src = '/assets/js/bootstrap.min.js';
		document.body.appendChild(bootstrapScript);
	
		// Load custom JavaScript
		const customScript = document.createElement('script');
		customScript.src = '/assets/js/custom.js';
		document.body.appendChild(customScript);
	
		// Load maplace JavaScript
		const maplaceScript = document.createElement('script');
		maplaceScript.src = '/assets/js/maplace.js';
		document.body.appendChild(maplaceScript);
	
		return () => {
		  // Clean up to prevent memory leaks
		  document.body.removeChild(bootstrapScript);
		  document.body.removeChild(customScript);
		  document.body.removeChild(maplaceScript);
		};
	  }, []);

	  useEffect(() => {
		$('.counter').each(function() {
		  var $this = $(this),
			  countTo = $this.attr('data-count');
		  
		  $({ countNum: $this.text() }).animate({
			countNum: countTo
		  },
		  {
			duration: 2000,
			easing: 'linear',
			step: function() {
			  $this.text(Math.floor(this.countNum));
			},
			complete: function() {
			  $this.text(this.countNum);
			}
		  });
		});
		
	
		// Cleanup function
		return () => {
		  // You can add cleanup code here if necessary
		};
	  }, []);


	


  return (
    <>


	<div class="slider">
	<div class="all-slide owl-item">			
		<div class="single-slide slide_1" style={{backgroundImage:'url(assets/img/slideg01.jpg)'}}>
		<div class="slider-overlay"></div>
		<div class="slider-text container text-right">
			<div class="slider-caption">
			<h1> Take the first learn </h1>
			<h2> Teachers open the door, but you must enter by yourself. </h2>
			<p> Anyone who has never made a mistake has never tried anything new.</p>
			</div>	
		</div>
		</div>
		<div class="single-slide slide_2" style={{backgroundImage:'url(assets/img/slideg04.jpg)'}}>
		<div class="slider-overlay"></div>
		<div class="slider-text container text-left">
			<div class="slider-caption">
			<h1> An investment in knowledge  </h1>
			<h2> pays the best interest </h2>
			<p> Apply yourself. Get all the education you can, but then, by God, do something. Don’t just stand there, make it happen. </p>
			</div>
		</div>
		</div>																							
	</div>
	<div class="about_text">
		<div class="container">
		<div id="counter">
			<div class="row text-center">					
			<div class="col-md-3 col-sm-6 col-xs-6">
				<div class="thumb">
				<div class="counter-value" data-count="10000"></div> 
				<div class="counter" data-count="300">0</div>
				<h4 style={{color:'#009999'}}> Students </h4>
				</div>
			</div>
			
			<div class="col-md-3 col-sm-6 col-xs-6">
				<div class="thumb">
				<div class="counter-value" data-count="200"></div> 	
				<div class="counter" data-count="12">0</div>
				<h4 style={{color:'#009999'}}> More than Staff </h4>
				</div>
			</div>
			<div class="col-md-3 col-sm-6 col-xs-6">
				<div class="thumb">
				<div class="counter-value" data-count="3500"></div>	
				<div class="counter" data-count="200">0</div>
				<h4 style={{color:'#009999'}}> Lectures</h4>
				</div>
			</div>
			<div class="col-md-3 col-sm-6 col-xs-6">
				<div class="thumb">
				<div class="counter-value" data-count="3500"></div>
				<div class="counter" data-count="500">0</div>
				<h4 style={{color:'#009999'}}> Notes</h4>
				</div>
			</div>
			</div>
		</div>
		</div>
	</div>
	</div>



    </>
  );
}

export default Slider;


